import * as Sentry from '@sentry/browser';
import { EnvironmentType, getEnvironment } from '../utils/environment';

/* eslint-disable init-declarations */
declare const COMMITHASH: string;
declare const SENTRY_DSN: string;
/* eslint-enable init-declarations */

document.addEventListener('DOMContentLoaded', () => {
    const Environment = getEnvironment();

    if (Environment === EnvironmentType.PROD) {
        // Sentry
        Sentry.init({
            dsn: SENTRY_DSN,
            release: COMMITHASH,
            whitelistUrls: [
                'www.goldenline.pl'
            ]
        });
    } else {
        /* eslint-disable no-console */
        console.warn('Not in production!');
        console.warn('Sentry not loaded');
        console.warn('Cookies for partners won\'t be set');
        /* eslint-enable no-console */
    }
});
